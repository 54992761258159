<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Union">
      <path
        d="M11.6959 14.6139C12.0314 13.8311 12.2757 12.7438 12.3411 11.4926H9.29913C9.36456 12.7438 9.60883 13.8311 9.94427 14.6139C10.1422 15.0757 10.3522 15.3856 10.5345 15.5654C10.7124 15.741 10.8078 15.7426 10.8201 15.7426C10.8325 15.7426 10.9278 15.741 11.1058 15.5654C11.288 15.3856 11.498 15.0757 11.6959 14.6139Z"
        fill="#555F70"
      />

      <path
        d="M12.2667 15.6874C12.8521 14.7384 13.2582 13.2292 13.3423 11.4926H15.8541C15.5889 13.4935 14.1583 15.1266 12.2667 15.6874Z"
        fill="#555F70"
      />

      <path
        d="M13.3548 10.4926H15.8881C15.7511 8.33478 14.2662 6.54321 12.2661 5.95068C12.8904 6.96204 13.3109 8.61035 13.3548 10.4926Z"
        fill="#555F70"
      />

      <path
        d="M9.94427 7.02521C9.57825 7.87933 9.3208 9.09589 9.28577 10.4926H12.3545C12.3194 9.09589 12.062 7.87933 11.6959 7.02521C11.498 6.56342 11.288 6.25354 11.1058 6.07367C10.9278 5.89807 10.8325 5.89648 10.8201 5.89648C10.8078 5.89648 10.7124 5.89807 10.5345 6.07367C10.3522 6.25354 10.1422 6.56342 9.94427 7.02521Z"
        fill="#555F70"
      />

      <path
        d="M9.3736 5.95166C8.74957 6.96307 8.32935 8.61096 8.28546 10.4926H5.75488C5.89178 8.33594 7.37512 6.54523 9.3736 5.95166Z"
        fill="#555F70"
      />

      <path
        d="M9.37286 15.6864C7.48297 15.1248 6.05383 13.4924 5.78882 11.4926H8.29785C8.38202 13.2286 8.78784 14.7374 9.37286 15.6864Z"
        fill="#555F70"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8199 0C4.84424 0 0 4.84424 0 10.8199C0 16.7955 4.84424 21.6397 10.8199 21.6397C16.7955 21.6397 21.6397 16.7955 21.6397 10.8199C21.6397 4.84424 16.7955 0 10.8199 0ZM1 10.8199C1 5.39648 5.39648 1 10.8199 1C16.2432 1 20.6397 5.39648 20.6397 10.8199C20.6397 16.2432 16.2432 20.6397 10.8199 20.6397C5.39648 20.6397 1 16.2432 1 10.8199Z"
        fill="#555F70"
      />
    </g>
  </svg>
</template>
