<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <circle cx="10.9597" cy="11.3199" r="10.3199" stroke="#555F70" />

    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25151 15.0278H14.6679C15.6602 15.0278 16.4637 14.1546 16.4597 13.0707V9.56911C16.4597 8.48966 15.6602 7.61206 14.6679 7.61206H7.25151C6.2632 7.61206 5.45969 8.48527 5.45969 9.56911V13.0707C5.45969 14.1502 6.25918 15.0278 7.25151 15.0278ZM9.78551 9.46583L12.9054 11.2649L9.78551 13.064V9.46583Z"
      fill="#555F70"
    />
  </svg>
</template>
