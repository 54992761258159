<template>
  <div
    class="bundle-card z-10 flex max-w-[368px] flex-col items-center justify-between rounded-3xl bg-white p-3 pt-8 drop-shadow-iai-1"
  >
    <div class="seller-details flex flex-col items-center justify-between">
      <img
        class="h-[90px] w-[90px] rounded-full border-1 border-white bg-white drop-shadow-iai-1"
        :src="bundle.seller_info.avatar"
      />

      <div
        data-testid="seller-user-name"
        class="mt-4 text-center font-serif text-3xl font-bold leading-10 text-theme-1"
      >
        {{ bundle.seller_info.user_name }}
      </div>

      <div class="mt-0.5 font-sans text-sm font-normal text-theme-1">
        {{ profileType }}
      </div>

      <SocialLinks class="mt-4" :links="props.bundle.seller_info.social" />
    </div>

    <div class="profile-details mt-7 flex max-w-[344px] flex-col justify-between rounded-3xl bg-rose-50 p-6">
      <div class="profile-name font-serif text-2xl font-bold text-theme-1">
        {{ bundle.name }}
      </div>

      <IAIPrice
        class="mt-1 font-serif text-2xl font-bold text-theme-3"
        :price="bundle.price"
        :lang="lang"
        :is-multi-currency-supported="false"
      />

      <div id="bundle-content">
        <div class="mt-6 font-sans text-sm font-semibold text-theme-1">
          {{ t("pages.sell-a-profile.profile.includes") }}
        </div>

        <ol
          v-for="item in bundle.content_list"
          :key="item"
          class="mt-2 list-inside list-disc font-sans text-sm font-normal text-theme-1"
        >
          <li class="mt-1 flex items-center">
            <img class="mr-2" :src="circleBullet.src" />

            <span>
              {{ item }}
            </span>
          </li>
        </ol>

        <div id="about">
          <div class="mt-6 font-sans text-sm font-semibold text-theme-1">
            {{ t("pages.sell-a-profile.profile.about") }}
          </div>

          <ReadMore class="mt-2">
            <pre class="whitespace-pre-line break-words font-sans text-sm font-normal text-theme-1">
                {{ bundle.description }}
              </pre
            >
          </ReadMore>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { IBundleDetailesResponse } from "@/features/sell-a-profile/services/api";
import { IAIPrice, ReadMore } from "@/core-ui-vue";
import { SocialLinks } from "@/core-ui-vue";
import { t } from "@/locales/vue";
import { getUnicodeBCP47 } from "@/locales/shared";
import circleBullet from "@/features/sell-a-profile/assets/bullet-points/circle.svg";

const lang = getUnicodeBCP47();

const props = defineProps<{
  bundle: IBundleDetailesResponse;
}>();

const profileType = computed(() =>
  props.bundle.profiles.length > 1
    ? t("pages.sell-a-profile.profile.ai-profile-bundle")
    : t("pages.sell-a-profile.profile.ai-profile")
);
</script>
