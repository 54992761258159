<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Union">
      <path
        d="M11.4279 15.3452V11.5547L12.8711 11.5581L13.4001 10.0212H11.4279V9.4787C11.4279 8.8894 11.5317 8.47754 11.7393 8.23981C11.947 8.00208 12.1746 7.88153 12.5999 7.88153C12.8906 7.88153 13.0093 7.90356 13.2279 7.94403C13.2787 7.95343 13.335 7.96387 13.4001 7.97528V6.45508C13.3279 6.43781 13.2648 6.41962 13.2034 6.40186C13.0029 6.34393 12.8195 6.29102 12.3889 6.29102C10.554 6.29102 9.68677 7.38263 9.68677 9.45862V10.0178H8.24023V11.5547H9.68677V15.3452H11.4279Z"
        fill="#555F70"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8199 0C4.84424 0 0 4.84424 0 10.8199C0 16.7955 4.84424 21.6397 10.8199 21.6397C16.7955 21.6397 21.6397 16.7955 21.6397 10.8199C21.6397 4.84424 16.7955 0 10.8199 0ZM1 10.8199C1 5.39648 5.39648 1 10.8199 1C16.2432 1 20.6397 5.39648 20.6397 10.8199C20.6397 16.2432 16.2432 20.6397 10.8199 20.6397C5.39648 20.6397 1 16.2432 1 10.8199Z"
        fill="#555F70"
      />
    </g>
  </svg>
</template>
