<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Union">
      <path
        d="M12.3654 10.8076C12.3654 11.6625 11.6723 12.3556 10.8174 12.3556C9.96246 12.3556 9.26941 11.6625 9.26941 10.8076C9.26941 9.9527 9.96246 9.25964 10.8174 9.25964C11.6723 9.25964 12.3654 9.9527 12.3654 10.8076Z"
        fill="#555F70"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5654 14.9356C14.3282 14.9356 14.9454 14.3185 14.9454 13.5557V8.05963C14.9454 7.30017 14.3249 6.67969 13.5654 6.67969H8.0694C7.30994 6.67969 6.68945 7.30017 6.68945 8.05963V13.5557C6.68945 14.3151 7.30994 14.9356 8.0694 14.9356H13.5654ZM13.3512 8.62061C13.5759 8.62061 13.7581 8.43848 13.7581 8.21375C13.7581 7.98901 13.5759 7.80688 13.3512 7.80688C13.1265 7.80688 12.9443 7.98901 12.9443 8.21375C12.9443 8.43848 13.1265 8.62061 13.3512 8.62061ZM10.8191 12.9284C10.2529 12.9284 9.72058 12.708 9.3205 12.3079C8.92041 11.9078 8.70007 11.3755 8.70007 10.8093C8.70007 10.2431 8.92041 9.71082 9.3205 9.31073C9.72058 8.91064 10.2529 8.69025 10.8191 8.69025C11.3853 8.69025 11.9177 8.91064 12.3177 9.31073C12.7178 9.71082 12.9382 10.2431 12.9382 10.8093C12.9382 11.3755 12.7178 11.9078 12.3177 12.3079C11.9142 12.708 11.3853 12.9284 10.8191 12.9284Z"
        fill="#555F70"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8199 0C4.84424 0 0 4.84424 0 10.8199C0 16.7955 4.84424 21.6397 10.8199 21.6397C16.7955 21.6397 21.6397 16.7955 21.6397 10.8199C21.6397 4.84424 16.7955 0 10.8199 0ZM1 10.8199C1 5.39648 5.39648 1 10.8199 1C16.2432 1 20.6397 5.39648 20.6397 10.8199C20.6397 16.2432 16.2432 20.6397 10.8199 20.6397C5.39648 20.6397 1 16.2432 1 10.8199Z"
        fill="#555F70"
      />
    </g>
  </svg>
</template>
