<template>
  <div class="flex flex-row items-center gap-2">
    <a v-if="props.links?.personal" :href="props.links.personal" target="_blank">
      <Personal class="icon" />
    </a>

    <a v-if="props.links?.facebook" :href="props.links.facebook" target="_blank">
      <Facebook class="icon" />
    </a>

    <a v-if="props.links?.instagram" :href="props.links.instagram" target="_blank">
      <Instagram class="icon" />
    </a>

    <a v-if="props.links?.youtube" :href="props.links.youtube" target="_blank">
      <Youtube class="icon" />
    </a>

    <a v-if="props.links?.tiktok" :href="props.links.tiktok" target="_blank">
      <Tiktok class="icon" />
    </a>

    <a v-if="props.links?.linkedin" :href="props.links.linkedin" target="_blank">
      <Linkedin class="icon" />
    </a>

    <a v-if="props.links?.twitter" :href="props.links.twitter" target="_blank">
      <Twitter class="icon" />
    </a>
  </div>
</template>

<script setup lang="ts">
import Facebook from "./social-icons/Facebook.vue";
import Twitter from "./social-icons/Twitter.vue";
import Instagram from "./social-icons/Instagram.vue";
import Personal from "./social-icons/Personal.vue";
import Tiktok from "./social-icons/Tiktok.vue";
import Youtube from "./social-icons/Youtube.vue";
import Linkedin from "./social-icons/Linkedin.vue";

export interface ISocialLinks {
  instagram?: string;
  facebook?: string;
  youtube?: string;
  tiktok?: string;
  linkedin?: string;
  twitter?: string;
  personal?: string;
}

const props = defineProps<{
  links: ISocialLinks;
  size?: string;
}>();
</script>

<style lang="scss" scoped>
.icon {
  width: v-bind(size);
  height: v-bind(size);
}
</style>
