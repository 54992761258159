<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <circle cx="10.9595" cy="11.3199" r="10.3199" stroke="#555F70" />

    <path
      d="M15.0816 9.40749C15.0879 9.49634 15.088 9.58522 15.088 9.67407C15.088 12.3842 13.0258 15.5069 9.25672 15.5069C8.09554 15.5069 7.01686 15.1705 6.1095 14.5866C6.27448 14.6057 6.43309 14.612 6.60442 14.612C7.56253 14.612 8.44453 14.2883 9.14884 13.7361C8.24783 13.7171 7.49274 13.1268 7.23259 12.3144C7.3595 12.3334 7.4864 12.3461 7.61966 12.3461C7.80366 12.3461 7.98769 12.3207 8.159 12.2763C7.21991 12.0859 6.51557 11.2608 6.51557 10.2643V10.239C6.78841 10.3913 7.10569 10.4865 7.44196 10.4992C6.88993 10.131 6.52827 9.50269 6.52827 8.79183C6.52827 8.41101 6.62977 8.06193 6.80745 7.75727C7.81634 9.00128 9.33285 9.81368 11.0333 9.90255C11.0016 9.75022 10.9826 9.59157 10.9826 9.43289C10.9826 8.30311 11.8963 7.38281 13.0321 7.38281C13.6222 7.38281 14.1552 7.63034 14.5295 8.0302C14.9927 7.94135 15.4369 7.76997 15.8303 7.53514C15.678 8.01117 15.3544 8.41103 14.9293 8.6649C15.3417 8.62049 15.7415 8.50622 16.1095 8.34756C15.8304 8.75375 15.4813 9.11551 15.0816 9.40749Z"
      fill="#555F70"
    />
  </svg>
</template>
