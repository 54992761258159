<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Union">
      <path
        d="M11.3037 6H12.8818C12.8818 7.24707 13.813 8.26758 15 8.30957V9.98242C14.231 9.96777 13.5024 9.69824 12.9087 9.24512V12.6602C12.9087 14.375 11.5869 15.7773 9.95459 15.7773C8.32227 15.7773 7 14.3887 7 12.6602C7 10.9453 8.29492 9.57129 9.90039 9.54297V11.2148C9.17188 11.2432 8.57861 11.8809 8.57861 12.6602C8.57861 13.4541 9.18555 14.1055 9.94092 14.1055C10.6963 14.1055 11.3037 13.4541 11.3037 12.6602V6Z"
        fill="#555F70"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.8203C0 4.84375 4.84424 0 10.8198 0C16.7954 0 21.6396 4.84375 21.6396 10.8203C21.6396 16.7959 16.7954 21.6396 10.8198 21.6396C4.84424 21.6396 0 16.7959 0 10.8203ZM10.8198 1C5.39648 1 1 5.39648 1 10.8203C1 16.2432 5.39648 20.6396 10.8198 20.6396C16.2432 20.6396 20.6396 16.2432 20.6396 10.8203C20.6396 5.39648 16.2432 1 10.8198 1Z"
        fill="#555F70"
      />
    </g>
  </svg>
</template>
