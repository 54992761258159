<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <circle cx="10.9597" cy="11.3199" r="10.3199" stroke="#555F70" />

    <path
      d="M8.52633 15.7012H6.45311V9.02468H8.52633V15.7012ZM7.4886 8.11395C6.82566 8.11395 6.28793 7.56483 6.28793 6.90187C6.28793 6.58342 6.41443 6.27802 6.6396 6.05285C6.86477 5.82767 7.17017 5.70117 7.4886 5.70117C7.80704 5.70117 8.11244 5.82767 8.3376 6.05285C8.56277 6.27802 8.68927 6.58342 8.68927 6.90187C8.68927 7.56483 8.15133 8.11395 7.4886 8.11395ZM16.2857 15.7012H14.217V12.4511C14.217 11.6765 14.2013 10.6832 13.139 10.6832C12.0611 10.6832 11.896 11.5247 11.896 12.3953V15.7012H9.82499V9.02468H11.8134V9.93542H11.8424C12.1192 9.41085 12.7953 8.85727 13.804 8.85727C15.9022 8.85727 16.2879 10.239 16.2879 12.0337V15.7012H16.2857Z"
      fill="#555F70"
    />
  </svg>
</template>
